body {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

main {
  padding: 0 12px;
}

.login-bg {
  background: linear-gradient(to bottom, #0b0001 0, #170204 25%, #37050c 66%, #42060e 100%);
  background-repeat: no-repeat;
}

.dashboard-page {
  background: #fff9f9;
}

header {
  padding: 0 0 0 40px;
}

.logo-wrapper {
  display: flex;
  align-items: flex-end;
}

.amc-login-logo {
  width: 204px;
  height: 85px;
}

.amc-cinema-logo {
  width: 163px;
  height: 69px;
}

.right-col h1 {
  font-size: 40px;
  font-family: "gorditamedium";
}

.right-col .btnWrap .dropdown {
  margin-bottom: 20px;
}

.col-right .dropdown-toggle:before {
  background: url("../assets/images/profile-img.png") no-repeat;
  background-size: 36px;
  content: "";
  height: 36px;
  width: 36px;
  left: 0;
  border-radius: 50px;
  position: absolute;
  border: none;
  margin-left: 10px;
  display: none;
}

.col-right .dropdown-toggle:after {
  background: url("../assets/images/arrow-down-2-svgrepo-com.svg") no-repeat;
  background-size: 22px;
  height: 20px;
  width: 20px;
  right: 0;
  position: absolute;
  border: none;
  margin-right: 10px;
  display: none;
}

.deals-login {
  background: linear-gradient(to bottom, #0b0001 0, #170204 25%, #37050c 66%, #42060e 100%);
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  padding: 100px 0 80px;
  min-height: 100vh;
}

.deals-logo {
  margin-bottom: 78px;
}

.form-input-grp input::placeholder {
  text-align: center;
  color: #9b9b9b;
  font-size: 18px;
}

.form-input-grp form {
  border: 1px solid transparent;
}

.form-input-grp .message {
  color: #ff0000;
  margin-top: 10px;
}

.verify-notification {
  margin: 50px 0 82px;
}

.copy-txt {
  color: #918d8d;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.31px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 25px;
}

.profile-img {
  width: 25px;
  border-radius: 20px;
  margin-right: 8px;
}

.user-info {
  display: flex;
  flex-direction: revert;
  align-items: center;
  justify-content: flex-end;
}

.user-info .btn.dropdown-toggle {
  height: 50px;
  width: 200px;
  font-size: 14px;
  color: #000;
  box-shadow: rgb(229 25 55 / 10%) 2px 2px 4px 0px;
}

.user-info .dropdown-item {
  font-size: 14px;
  padding: 10px 30px;
}

.col-right {
  border-bottom: 1px solid #fad1d6;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  height: 122px;
  padding-right: 40px;
}

.calender-icon {
  margin-right: 6px;
  width: 21px;
  height: 21px;
}

.calender-sec {
  margin-right: 60px;
  font-size: 16px;
  line-height: 17px;
  font-family: "gorditamedium";
  display: flex;
  align-items: center;
}

.notify-sec {
  margin-right: 35px;
  position: relative;
  display: inline-block;
}

.user-dashboard {
  background: #fff;
  border-radius: 20px;
  padding: 6px 10px;
  font-weight: 600;
}

.dashboard figure {
  margin-bottom: 0;
}

.left-col {
  background: #fff;
  border-top: 1px solid #fad1d6;
  border-right: 1px solid #fad1d6;
  border-top-right-radius: 100px;
  margin-top: 42px;
  padding-top: 65px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  width: 17.5%;
  min-height: calc(100vh - 122px - 42px);
}

.left-col ul.left-col-nav li {
  padding: 20px 0;
}

.left-col ul.left-col-nav li a {
  padding: 7px 0;
  padding-left: 48px;
  color: #918d8d;
  line-height: 36px;
  font-size: 20px;
  display: block;
  position: relative;
  font-family: "gorditamedium";
  cursor: pointer;
}

.left-col ul.left-col-nav li a:hover {
  color: #000;
}

.left-col ul.left-col-nav li a:before {
  content: "";
  background: #e51937;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.5s;
}

.left-col ul.left-col-nav li.active a:before,
.left-col ul.left-col-nav li a:hover:before {
  opacity: 1;
}

.left-col ul.left-col-nav li a span {
  display: block;
  padding-left: 38px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 28px;
  transition: all 0.5s;
}

.left-col ul.left-col-nav li.active a span {
  color: #000;
}

.left-col ul.left-col-nav li.icon-dashboard a span {
  background-image: url("../assets/images/Home-Unselected.svg");
  background-size: 26px;
}

.left-col ul.left-col-nav li.icon-dashboard.active a span,
.left-col ul.left-col-nav li.icon-dashboard a:hover span {
  background-image: url("../assets/images/Home-selected.svg");
}

.left-col ul.left-col-nav li.icon-company a span {
  background-image: url("../assets/images/Manage-Companies-Unselected.svg");
}

.left-col ul.left-col-nav li.icon-company.active a span,
.left-col ul.left-col-nav li.icon-company a:hover span {
  background-image: url("../assets/images/Manage-Companies-selected.svg");
}

.left-col ul.left-col-nav li.icon-tier a span {
  background-image: url("../assets/images/Create-Tiers-unselected.svg");
  background-size: 26px;
}

.left-col ul.left-col-nav li.icon-tier.active a span,
.left-col ul.left-col-nav li.icon-tier a:hover span {
  background-image: url("../assets/images/Create-Tiers-selected.svg");
}

.left-col ul.left-col-nav li.icon-user-mgmt a span {
  background-image: url("../assets/images/User-managements-Unselecetd.svg");
}

.left-col ul.left-col-nav li.icon-user-mgmt.active a span,
.left-col ul.left-col-nav li.icon-user-mgmt a:hover span {
  background-image: url("../assets/images/User-managements-selecetd.svg");
}

.left-col ul.left-col-nav li.icon-deal-mgmt a span {
  background-image: url("../assets/images/Deal-management-Unselected.svg");
}

.left-col ul.left-col-nav li.icon-deal-mgmt.active a span,
.left-col ul.left-col-nav li.icon-deal-mgmt a:hover span {
  background-image: url("../assets/images/Deal-management-selected.svg");
}

.left-col ul.left-col-nav li.icon-report a span {
  background-image: url("../assets/images/Reports-unselected.svg");
}

.left-col ul.left-col-nav li.icon-report.active a span,
.left-col ul.left-col-nav li.icon-report a:hover span {
  background-image: url("../assets/images/Reports-selected.svg");
}

.noData {
  padding: 50px 30px !important;
  font-size: 20px !important;
  color: #918d8d !important;
  font-family: "gorditamedium";
}

.right-col nav {
  margin: 20px 0 24px;
}

.right-col nav li {
  display: inline-block;
  margin-right: 14px;
}

.right-col nav ul {
  padding-left: 0;
  display: flex;
  flex-direction: row;
}

.right-col nav ul li a {
  text-decoration: none;
  color: #918d8d;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 22px;
  font-size: 14px;
  line-height: 15px;
}

.right-col nav ul li:last-child a {
  color: #e51937;
  font-family: "gorditamedium";
}

.right-col nav ul li a:before {
  width: 8px;
  height: 9px;
  content: "";
  position: absolute;
  background-size: 6px;
  left: 0;
  background-repeat: no-repeat;
}

.right-col nav ul li a:before {
  background-image: url("../assets/images/nav-arrow.svg");
}

.right-col nav ul li:last-child a:before {
  background-image: url("../assets/images/nav-arrow-active.svg");
}

.right-col nav ul li:first-child a {
  padding-left: 0;
}

.right-col nav ul li:first-child a:before {
  content: none;
}

.right-col {
  margin: 35px 0;
  padding: 0 40px 0 90px;
  width: 82.5%;
}

.btn-sec {
  display: flex;
  justify-content: flex-end;
}

.icon-add {
  background-image: url("../assets/images/icon-add.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 20px;
  height: 15px;
  display: inline-block;
}

.icon-download {
  background-image: url("../assets/images/Download.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 20px;
  height: 15px;
  display: inline-block;
}

.icon-delete {
  background-image: url("../assets/images/icon-delete.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left center;
  width: 20px;
  height: 100%;
  /* height: 20px;
  display: inline-block; */
  transition: all 0.5s;
}

.btn-outline-secondary:hover .icon-delete,
.btn-outline-secondary:active .icon-delete {
  background-image: url("../assets/images/icon-delete-active.svg");
}

.icon-prev {
  background-image: url("../assets/images/form-previous-svgrepo-com.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 20px;
  height: 18px;
  display: inline-block;
  cursor: pointer;

}

.icon-prev.disabled,
.icon-next.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon-next {
  background-image: url("../assets/images/form-next-svgrepo-com.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 20px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}

.table tbody tr td.email-address {
  text-transform: none;
  word-break: break-all;
}

.table {
  margin: 54px 0 30px;
}

.table thead tr th {
  height: 70px;
  padding: 10px 21px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 17px;
  font-family: "gorditabold";
}

.table tbody tr td {
  height: 58px;
  padding: 10px 21px;
  font-size: 16px;
  line-height: 17px;
}

.font-gorita-medium {
  font-family: "gorditamedium";
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: transparent;
}

.table tr {
  vertical-align: middle;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: transparent;
  color: #000;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: transparent;
  color: #000;
}

.table-striped>tbody>tr:nth-of-type(even) {
  --bs-table-accent-bg: #fff;
  color: #000;
}

.table> :not(caption)>*>* {
  border-bottom: none;
  padding: 12px 8px;
}

.table th:first-child {
  border-top-left-radius: 21px;
}

/* .manage-table.table th:first-child {
  text-align: center;
} */
.table th:last-child {
  border-top-right-radius: 21px;
}

.table td:first-child {
  border-top-left-radius: 10.8px;
  border-bottom-left-radius: 10.8px;
}

.table td:last-child {
  border-top-right-radius: 10.8px;
  border-bottom-right-radius: 10.8px;
}

.table-responsive {
  margin-bottom: 30px;
}

.table .form-check {
  display: flex;
  justify-content: center;
}

/* .table-company-name {
  min-width: 235px;
}
.table-name,
.table-show-date {
  min-width: 253px;
}
.table-email {
  min-width: 330px;
}
.table-film-name {
  min-width: 195px;
} */

.table .form-check-input:checked {
  background-color: #e51937;
  border-color: #e51937;
}

.table .form-check-input {
  width: 30px;
  height: 30px;
  border: 2px solid #e51937;
  background-color: transparent;
}

.table .form-check-input:focus {
  border-color: #e51937;
  outline: 0;
  box-shadow: none;
}

.table .form-check-input:active {
  filter: none;
}

.link {
  color: #00a8e1;
  text-decoration: underline;
  font-family: "gorditamedium";
  white-space: nowrap;
  font-size: 15px;
  line-height: 12px;
}

.link:hover {
  color: #e51937;
}

.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #e3dede;
  border: 3.5px solid transparent;
  background-clip: content-box;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #e3dede;
  border-radius: 10px;
}

.pagination {
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  font-size: 15px;
  line-height: 12px;
}

.page-count {
  margin-right: 5px;
  font-family: "gorditamedium";
}

.total-page {
  margin-left: 5px;
  font-weight: 500;
}

.pagenation-arrow {
  display: flex;
  align-items: center;
}

.manage-dashboard ul {
  display: flex;
  flex-direction: row;
  gap: 65px;
}

.manage-dashboard.top-to-dashboard {
  margin-top: 70px;
}

.manage-dashboard ul li {
  width: 210px;
  /* margin-right: 65px; */
}

.manage-dashboard ul li aside {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 260px;
  box-shadow: -10px 64px 56px rgba(227, 192, 192, 0.191702);
  border-radius: 40px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 33px 30px;
  transition: all 0.5s;
  cursor: pointer;
}

.manage-dashboard ul li aside p {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 27px;
  font-family: "gorditamedium";
}

.manage-dashboard ul li aside:hover {
  background: #000;
}

.manage-dashboard ul li aside:hover .manage-dashboard-detail {
  color: #fff;
}

.manage-dashboard-detail {
  color: #000;
  transition: all 0.5s;
}

.icon-manage-company {
  background-image: url("../assets/images/Manage\ Companies.svg");
  background-size: 52px;
  background-repeat: no-repeat;
  width: 53px;
  height: 58px;
  display: inline-block;
  margin-bottom: 30px;
}

.icon-manage-tier {
  background-image: url("../assets/images/Manage\ tiers.svg");
  background-size: 52px;
  background-repeat: no-repeat;
  width: 54px;
  height: 54px;
  display: inline-block;
  margin-bottom: 30px;
}

.icon-manage-deals {
  background-image: url("../assets/images/Manage\ deals.svg");
  background-size: 72px;
  background-repeat: no-repeat;
  width: 73px;
  height: 48px;
  display: inline-block;
  margin-bottom: 30px;
}

.icon-manage-users {
  background-image: url("../assets/images/Manage\ users.svg");
  background-size: 56px;
  background-repeat: no-repeat;
  width: 58px;
  height: 46px;
  display: inline-block;
  margin-bottom: 30px;
}

.icon-view-reports {
  background-image: url("../assets/images/View\ reports.svg");
  background-size: 38px;
  background-repeat: no-repeat;
  width: 42px;
  height: 48px;
  display: inline-block;
  margin-bottom: 30px;
}

.powered-txt {
  font-size: 14px;
  position: absolute;
  position: fixed;
  bottom: 0;
  padding-left: 35px;
  margin-bottom: 10px;
}

.influx-logo {
  background-image: url("../assets/images/Influx\ logo.svg");
  background-size: initial;
  background-repeat: no-repeat;
  width: 60px;
  height: 20px;
  display: inline-block;
  margin-left: 4px;
}

/*overwrite css*/
.form-input-grp {
  width: 420px;
  margin: 0 auto;
}

.form-input-grp .input-form {
  margin-bottom: 26px;
}

.enter-password,
.confirm-password {
  position: relative;
  display: flex;
  align-items: center;
}

.hours-dorpdown .dropdown-menu .form-check-inline {
  margin-right: 0;
  cursor: auto;
}

.hours-dorpdown .dropdown-menu .form-check-input {
  margin-right: 0;
  cursor: pointer;
}

.hours-dorpdown .hour-label {
  margin-left: 32px;
  color: #000;
  cursor: pointer;
}


/* 
.enter-password span:after {
  background: url("../assets/images/eye-open.svg") no-repeat;
  background-size: 22px;
  width: 22px;
  height: 13px;
  display: inline-block;
  content: "";
  position: absolute;
  right: 20px;
}

.confirm-password span:after {
  background: url("../assets/images/eye-open.svg") no-repeat;
  background-size: 22px;
  width: 22px;
  height: 13px;
  display: inline-block;
  content: "";
  position: absolute;
  right: 20px;
} */

.form-control {
  padding: 5px 20px;
  border-radius: 33px;
  text-align: center;
  color: #000;
  height: 66px;
  width: 100%;
  font-size: 18px;
  border: 1px solid rgba(229, 25, 55, 0.3);
  background: #fff;
}

.form-control:focus {
  border: 1px solid #fff;
  box-shadow: none;
}

.form-floating .form-control.input-error {
  border: 1px solid #e51937;
  outline: 1px solid #e51937;
  box-shadow: none;
}

.invalid-text {
  color: #e51937;
  font-size: 12px;
  line-height: 13px;
  padding: 8px 35px;
  position: relative;
  display: flex;
  align-items: center;
}

.invalid-text::before {
  background: url("../assets/images/Warning.svg") no-repeat;
  background-size: 13px;
  width: 13px;
  height: 11px;
  display: inline-block;
  content: "";
  position: absolute;
  left: 20px;
}

.right-col .company-input-form .form-control {
  height: 64px;
  padding: 5px 20px;
}

.right-col .company-input-form .form-floating>label {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  color: #918d8d;
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding: 28px 20px 10px;
  font-size: 16px;
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  font-size: 14px;
  padding-left: 20px;
}

input[type="email"] {
  text-transform: none !important;
}

.forget-psw-link {
  font-size: 16px;
  color: #00a8e1;
  line-height: 14px;
  font-family: "gorditamedium";
  text-decoration: underline;
}

.forget-psw-link:hover {
  color: #e51937;
  text-decoration: none;
}

.company-statistic {
  margin: 70px 0 102px;
}

.company-statistic ul {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 65px;
}

.company-statistic ul li {
  width: 210px;
  /* margin-right: 65px; */
}

.company-statistic p {
  font-size: 72px;
  line-height: 78px;
  color: #e51937;
  font-weight: bold;
  font-family: "gorditabold";
  display: flex;
  flex-direction: column;
}

.company-statistic p span {
  color: #4a4a4a;
  font-size: 19px;
  line-height: 24px;
  font-family: "gorditamediumitalic";
  font-weight: normal;
}

.upload {
  width: 100%;
  padding: 20px;
  height: 150px;
  background: #fff;
  border: 1px dashed rgba(229, 25, 55, 0.5);
  border-radius: 20px;
  color: #00a8e1;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "gorditamedium";
  position: relative;
}

.upload:before {
  background-image: url("../assets/images/Upload.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 8px;
  content: "";
}

.company-input-form {
  margin: 40px 0;
}

.company-input-form .form-control {
  text-align: left;
}

.company-input-form .upload .form-control {
  border: 1px solid #fad1d6;
  text-align: left;
  position: absolute;
  opacity: 0;
  height: 100%;
}

.company-input-form .form-control:focus {
  box-shadow: rgb(229 25 55 / 12%) 0px 0px 2px 2px;
}

.back-link {
  display: flex;
  align-items: center;
  font-family: "gorditaregular";
  margin-bottom: 65px;
  color: #000;
  width: max-content;
  cursor: pointer;
}

.back-link:hover {
  color: #000;
  text-decoration: underline;
}

.back-link:before {
  background-image: url("../assets/images/Back.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 5px;
  content: "";
}

.form-switch {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.form-switch .form-check-input {
  width: 60px;
  height: 30px;
  border: 2px solid #e51937;
  margin: 0 15px;
  filter: none;
}

.form-switch label {
  font-family: "gorditamedium";
  margin-bottom: 0;
  font-size: 16px;
  line-height: 40px;
}

.form-switch .toggle-activate {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-switch .form-check-input:checked {
  background-color: #e51937;
  border-color: #e51937;
}

.form-switch .form-check-input:checked:focus {
  background-image: url("../assets/images/circle-checked.svg");
}

.form-switch .form-check-input {
  background-image: url("../assets/images/circle-svgrepo-com.svg");
  background-size: 22px;
}

.form-switch .form-check-input:focus {
  background-image: url("../assets/images/circle-svgrepo-com.svg");
  box-shadow: none;
}

.dropdown-menu.show {
  padding: 0;
}

.dropdown-menu.show li a:hover {
  background-color: #fff9f9;
  color: #e51937;
}

.dropdown-menu.show li:last-child a {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.dropdown-item {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.company-input-form .dropdown-toggle {
  background: #fff;
  border: 1px solid #fad1d6;
  color: #918d8d;
  padding: 5px 20px;
  border-radius: 25px;
  box-shadow: none;
  width: 100%;
  font-weight: normal;
  justify-content: flex-start;
  height: 56px;
  font-size: 16px;
}

.dropdown-toggle:after {
  background: url(../assets/images/dropdown.svg) no-repeat;
  background-size: 20px;
  height: 20px;
  width: 20px;
  right: 0;
  position: absolute;
  border: none;
  margin-right: 10px;
  transition: all 0.5s;
}

.company-input-form .form-label {
  font-family: "gorditamedium";
  margin-bottom: 10px;
  font-size: 16px;
}

.company-input-form .btnWrap {
  justify-content: flex-start;
}

.company-input-form .btnWrap .btn-small {
  margin-left: 0;
}

.instruction {
  font-family: "gorditamedium";
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.instruction::before {
  background-image: url("../assets/images/Instructions.svg");
  background-size: 23px;
  background-repeat: no-repeat;
  width: 23px;
  height: 22px;
  display: inline-block;
  margin-right: 8px;
  content: "";
}

.add-step {
  margin-top: 112px;
}

.add-step ul {
  position: relative;
}

.add-step ul li:before {
  font-weight: bold;
  display: inline-block;
  width: 16px;
  content: "";
  background: #e51937;
  height: 5px;
  width: 5px;
  position: absolute;
  border-radius: 5px;
  left: 0;
  top: 50%;
}

.add-step ul li {
  margin-bottom: 12px;
  position: relative;
  padding-left: 15px;
}

.add-step ul li .a-link {
  text-decoration: underline;
  color: #e51937;
}

.form-floating>.form-control.noCapitalize:not(:placeholder-shown) {
  text-transform: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotateAntiCW {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-div {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(202, 198, 198, 0.5);
  z-index: 10000000;
  cursor: pointer;
}

.loader-div .loading-spinner {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top: 7px solid #e51937;
  border-bottom: 7px solid #e51937;
  animation: rotateAntiCW 1.8s linear infinite;
}

.loader-div .loading-spinner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-left: 7px solid rgba(229, 25, 55, 0.5);
  border-right: 7px solid rgba(229, 25, 55, 0.5);
  animation: rotate 1s linear infinite;
}

.eye-icon {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
}

.company-input-form .dropdown-toggle.input-error {
  border: 1px solid #e51937;
  outline: 1px solid #e51937;
  box-shadow: none;
}

.message {
  margin-top: 15px;
}

.form-control.input-error {
  border: 1px solid #e51937;
  outline: 1px solid #e51937;
  box-shadow: none;
}

.errorMessage {
  margin-bottom: 26px;
}

.noTierMessage {
  color: #e51937 !important;
  font-family: "gorditamedium";
}

.link-added {
  cursor: pointer;
}

.roleName {
  text-transform: capitalize;
}

.upload.filename::before {
  display: none;
}

.upload.input-error {
  border: 3px solid #e51937;
}

.list-of-deals-section {
  border: 1px solid rgba(155, 155, 155, 0.07);
  border-radius: 20px 20px 20px 20px;
  background: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  /* width: 94%; */
}

.deals-card {
  background: #ffffff;
  padding: 60px 107px 60px 41px;
}

.deals-card .deal .deals-details {
  padding-bottom: 4px;
}

.list-of-deals-section .deals-card:first-child {
  border-radius: 20px 20px 0px 0px;
}

.list-of-deals-section .deals-card:last-child {
  border-radius: 0 0 20px 20px;
}

.deals-card .deal-content {
  margin-left: 15px;
}

.deals-card .deal.open {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.deals-card .deal-content .title {
  font-family: 'gorditaregular';
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;
}

.deals-card .deal-content .content {
  font-family: "gorditaregular";
  font-size: 18px;
  color: #000;
}

.deals-card .deal-content .content .bold-text {
  font-family: "gorditabold";
}

.deals-card .deal-content .content.info {
  font-family: "gorditalightitalic";
  font-weight: 600;
}

.deal-button input[type="checkbox"] {
  visibility: hidden;
}

.deal-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  white-space: nowrap;
}

.deal-button .toggle {
  height: 44px;
  width: 92px;
  background: #9b9b9b;
  border-radius: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.on-switch,
.off-switch {
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: "gorditamedium";
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.deal-button .toggle label {
  display: block;
  width: 39px;
  height: 39px;
  border-radius: 50px;
  position: absolute;
  background: #fff;
  bottom: 3px;
  left: 3px;
  transition: all 0.75s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.deal-button .toggle.toggle-on {
  background: #4cbb17;
}

.deal-button .toggle.toggle-on label {
  transition: transform all 0.75s ease-in-out;
  transform: translate(119%, 0px);
  background: #fff;
}

.deal-button .toggle.toggle-on label:before {
  content: url("../assets/images/right.svg");
}

.deal-button .toggle.toggle-off label:before {
  content: url("../assets/images/cross.svg");
}

.create-deal-link {
  color: rgba(0, 168, 225, 1);
  text-decoration: underline;
  font-size: 15px;
  line-height: 12px;
  font-family: "gorditamedium";
  cursor: pointer;
}

.deals-card .card {
  width: 95%;
  margin-left: 46px;
  border: 0;
  /* border-top: 1px solid rgba(145, 141, 141, 0.2); */
  border-radius: 0;
  /* padding: 40px 0; */
  padding: 40px 0 0 0;
}

.deals-card .card .title {
  font-size: 22px;
  font-family: 'gorditaregular';
}

.deals-card .card .title.cinema-section-title {
  font-size: 24px;
  font-family: 'gorditamedium';
}

.deals-card .card .sar-info {
  font-size: 18px;
  line-height: 25px;
  font-family: "gorditaregular";
}

.deals-card .card .deal-row {
  border-top: 1px solid rgba(145, 141, 141, 0.2);
  padding: 40px 20px;
}

.deals-card .card .deal-row .left {
  margin-bottom: 10px;
}

.deals-card .card .edit-deal {
  border: 0.68px solid #00a8e1;
  border-radius: 20px;
  background: #fff;
  color: #00a8e1;
  font-size: 12px;
  line-height: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.deals-card .card .edit-deal .icon-edit {
  background-image: url("../assets/images/edit.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.modal-footer .icon-edit {
  background-image: url("../assets/images/edit-white-icon.png");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.menu-item {
  padding: 0 26.55px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item-wrapper {
  font-size: 14px;
  line-height: 20px;
  font-family: "gorditamedium";
  text-transform: uppercase;
  border-radius: 21.6px 21.6px 0px 0px;
  padding: 20px 0;
  height: 60px;
  border: 1px solid #000;
  background: #000;
  color: #fff;
}

.menu-item-wrapper.active {
  border: 1px solid #e51937;
  background: #e51937;
  color: #fff;
}

.scroll-menu-arrow {
  cursor: pointer;
}

.scrollable-tier-section .horizontal-menu {
  column-gap: 5px;
}

.scroll-menu-arrow .arrow-prev img,
.scroll-menu-arrow .arrow-next img {
  width: 25px;
}

.scroll-menu-arrow .arrow-prev img {
  transform: rotate(180deg);
}

.scroll-menu-arrow--disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* .deal-modal {
  z-index: 0;
} */

.deal-modal .modal-title {
  font-size: 22px;
  line-height: 40px;
  font-family: "gorditamedium";
}

.deal-modal .modal-body .field {
  margin-bottom: 30px;
}

.deal-modal .modal-body.company-input-form {
  margin: 0;
}

.deal-modal .modal-body .form-control.textarea {
  height: 125px;
  border-radius: 20px;
  padding: 15px 17px;
}

.deal-modal .modal-body .form-control.textarea::placeholder {
  color: #d3d1d1;
  font-size: 16px;
  padding: 5px;
}

.deal-modal .modal-body .day-badges {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.day-badges .badge {
  border-radius: 20px;
  font-size: 13px;
  line-height: 25px;
  width: 63px;
  height: 36px;
}

.deal-modal .modal-body .day-badges .badge {
  border: 1px solid #e51937;
  color: #e51937;
  background: #fff;
  cursor: pointer;
}

.deal-modal .modal-body .day-badges .badge.selected-day {
  background: #e51937;
  color: #ffffff;
  border: 1px solid #e51937;
}

.deal-modal input::placeholder {
  color: rgba(145, 141, 141, 0.5);
  font-size: 16px;
  font-family: "gorditaregular";
}

.deal-modal .form-label {
  padding-left: 14px;
}

.deal-modal .modal-body .btnWrap {
  justify-content: flex-end;
}

.deal-modal .space-bottom {
  margin-bottom: 40px;
}

.field {
  display: flex;
  flex-direction: column;
}

.capping-details .cappingfield .small-label {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 13px;
  font-family: "gorditaregular";
  width: 218px;
}

.dropdown .cinema-section-title {
  margin-bottom: 27px;
}

.deals-card .capping-body {
  padding-top: 0;
}

.deals-card .capping-body .form-control {
  width: 160px;
  font-size: 18px;
  font-family: "gorditamedium";
}

.deals-card .capping-details {
  justify-content: space-between;
}

.capping-body input::placeholder {
  color: rgba(145, 141, 141, 0.5);
  font-size: 16px;
  font-family: "gorditaregular";
}

.cinemas-section .form-check-input {
  width: 30px;
  height: 30px;
  border: 2px solid #e51937;
  background-color: transparent;
  margin-right: 30px;
  min-width: 30px;
  margin-top: 0;
}

.cinemas-section .form-check-input:checked {
  background-color: #e51937;
  border-color: #e51937;
}

.cinemas-section .input-wrap {
  padding: 6px;
}

.cinemas-section .input-wrap input {
  width: 100%;
  height: 40px;
  border: 1px solid #fad1d6;
  padding-left: 13px;
}

.cinemas-section.movies-section .dropdown .dropdown-menu {
  width: 100%;
}

.cinemas-section .dropdown .dropdown-menu li {
  display: flex;
  align-items: center;
}

.form-check span {
  vertical-align: sub;
  word-break: break-word;
}

.form-check {
  display: flex;
  overflow-wrap: break-word;
  white-space: normal;
}

.cinema-section .dropdown .dropdown-item {
  height: 100%;
  display: flex;
}

.selected-section {
  border-top: 1px solid rgba(145, 141, 141, 0.2);
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: center;
}

.selected-section .title {
  font-size: 16px !important;
}

.selected-section .badges-grp {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.selected-section .badge {
  background: rgba(229, 25, 55, 0.05);
  border: 1px solid rgba(229, 25, 55, 0.1);
  border-radius: 37.7px;
  padding: 14px;
  color: #000;
  font-family: "gorditaregular";
  font-weight: 500;
  font-size: 13px;
}

.selected-section .cross-icon {
  cursor: pointer;
}

.modal.show {
  display: block;
}

.close-modal {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.center-button {
  justify-content: center;
}

.dropdown .dropdown-menu li:nth-of-type(odd) {
  background: rgba(255, 249, 249, 1);
}

.table.table-more-space thead tr th {
  padding: 10px 40px;
}

.table.table-more-space tbody tr td {
  padding: 10px 40px;
}

.addCompanyWrapper .form-check.form-switch {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  align-items: flex-start;
}

.info-icon {
  width: 17px;
  margin-left: 5px;
}

.add-deal-modal .modal-footer {
  justify-content: center;
}

.deals-card .card .delete-deal {
  border: 0.68px solid #e51937;
  border-radius: 20px;
  background: #fff;
  color: #e51937;
  font-size: 12px;
  line-height: 10px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  margin-left: 10px;
}

.deals-card .card .delete-deal .icon-delete {
  background-image: url("../assets/images/delete-icon.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: left;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.error-text {
  font-size: 14px;
  line-height: 13px;
  color: #e51937;
  font-family: gorditaregular;
  margin-left: 16px;
  margin-top: 8px;
}

.a-link {
  color: #e51937;
  cursor: pointer;
}

/* .swal2-actions.swal2-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(202, 198, 198, 0.5);
  z-index: 10000000;
  cursor: pointer;
} */

.swal2-actions.swal2-loading .swal2-loader {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top: 7px solid #e51937;
  border-bottom: 7px solid #e51937;
  animation: rotateAntiCW 1.8s linear infinite;
}

.swal2-actions.swal2-loading .swal2-loader::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-left: 7px solid rgba(229, 25, 55, 0.5);
  border-right: 7px solid rgba(229, 25, 55, 0.5);
  animation: rotate 1s linear infinite;
}

.upload.logo::before {
  display: none;
}

.upload-logo {
  height: 100%;
  width: 309px;
  object-fit: contain;
}

.edit-logo-link {
  border: 0.68px solid #00a8e1;
  border-radius: 20px;
  background: #fff;
  color: #00a8e1;
  font-size: 12px;
  line-height: 10px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
}

.edit-logo {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.input-group-text {
  border-color: rgba(229, 25, 55, 0.3);
  border-top-left-radius: 33px;
  border-bottom-left-radius: 33px;
  background: #fff;
  color: #918d8d;
}

.up-logo {
  visibility: hidden;
}

.view-user-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.view-user-page .table {
  margin-top: 0;
}

.user-progress-bar .progress {
  height: 32px;
  border-radius: 30px;
}

.user-progress-bar .progress-bar {
  font-weight: 900;
  color: #000;
}

.duplicate-alert {
  cursor: pointer;
}

.duplicate-users-list-card .card {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(229, 25, 55, 0.1);
  border-radius: 20px;
}

.duplicate-users-list-card .card .header {
  background: #fce3e6;
  border-radius: 19px 19px 0px 0px;
  padding: 19px 0 12px 31px;
  font-size: 18px;
  font-family: gorditaMedium;
  line-height: 30px;
  display: flex;
  align-items: baseline;
  gap: 7px;
  color: #e51937;
}

.duplicate-users-list-card .card .card-body {
  padding: 20px 0 31px 31px;
}

.duplicate-users-list-card .card .card-title {
  font-family: gorditaMedium;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 11px;
  color: #000000;
}

.duplicate-user-body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.duplicate-users-list-card .card .duplicate-users-email {
  color: #6b6b6b;
}

.cinemas-section .dropdown-menu.show::-webkit-scrollbar,
.report-dropdown-group .dropdown-menu.show::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cinemas-section .dropdown-menu.show::-webkit-scrollbar-track,
.report-dropdown-group .dropdown-menu.show::-webkit-scrollbar-track {
  background-color: #e3dede;
  border: 3px solid white;
  background-clip: content-box;
  border-radius: 10px;
}

.cinemas-section .dropdown-menu.show::-webkit-scrollbar-thumb,
.report-dropdown-group .dropdown-menu.show::-webkit-scrollbar-thumb {
  background: #e3dede;
  border-radius: 10px;
}

.cinemas-section .dropdown-menu.show {
  max-height: 324px;
  overflow-y: scroll;
}

.cinemas-section .dropdown-menu {
  background-clip: border-box;
}

.user-info .dropdown-menu.show li {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.redirect-link {
  cursor: pointer;
}

.actions .btn.btn-outline-dark {
  border-color: #e51937;
  color: #e51937;
  line-height: 0;
  padding: 8px 11px;
}

.actions .btn.btn-outline-dark:hover {
  background-color: #e51937;
  color: #fff;
}

.actions .btn.btn-outline-dark i {
  font-size: 18px;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.table.reports-table tbody tr td,
.table.reports-table thead tr th {
  white-space: nowrap;
  padding: 10px 35px;
}

.table.reports-table thead tr th {
  text-transform: uppercase;
}

.report-dropdown-group {
  /* display: flex; */
  margin-top: 30px;
  /* justify-content: flex-end; */
}

.report-dropdown-group .dropdown {
  /* width: 244px; */
  width: 100%;
}

.report-dropdown-group .dropdown .dropdown-menu.show {
  min-height: 261px;
  height: 261px;
  overflow-y: auto;
  min-width: auto;
}

.dropdown-menu.report-tier-list.show {
  height: auto !important;
  min-height: auto !important;
}

.btn-download {
  width: 160px;
  margin-top: 30px;
}

.report-dropdown-group .dropdown .dropdown-menu.report-type-dropdown.show {
  height: auto;
  min-height: auto;
  min-width: auto;
}

.company-input-form .dropdown-toggle.report-drop {
  height: 50px;
}

.sec-row {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0;
}

.btn.dropdown-toggle.report-drop {
  min-width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 35px;
  text-align: left;
  display: block;
}

.active-selected {
  color: #e82e37;
}

.dropdown-grp {
  padding-right: 150px;
  flex-grow: 1;
}

.col-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.company-statistic p span:first-child {
  color: #e51937;
  font-weight: bold;
  font-family: "gorditabold";
  font-size: 72px;
  line-height: 56px;
}

.manage-dashboard ul li aside p {
  font-size: 20px;
}

.summery-line {
  padding-left: 13px;
  padding-top: 15px;
  text-align: center;
}

.summery-line span {
  font-size: 18px;
  font-weight: bold;
}

.summery-line span b.date {
  font-size: 14px;
}

.summery-line span b {
  color: #e51937;
}

.sec-row.fixedRightButton {
  flex: 1 0 0;
  position: relative;
}

.sec-row.fixedRightButton>.row {
  flex: 1 0 0;
  padding-right: 140px;
}

.sec-row.fixedRightButton>.btnWrap {
  position: absolute;
  top: 0;
  right: 0;
}


.col-right .dropdown-toggle {
  display: flex;
  justify-content: space-between !important;
}

.col-right .dropdown-toggle .user-img {
  background: url("../assets/images/profile-img.png") no-repeat;
  background-size: 36px;
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 50px;
}

.col-right .dropdown-toggle .arrow {
  background: url("../assets/images/arrow-down-2-svgrepo-com.svg") no-repeat;
  background-size: 22px;
  height: 20px;
  width: 20px;
}

.col-right .dropdown-toggle .arrow.down {
  transform: rotate(180deg);
  margin-top: 6px;
}


.search-box {
  position: relative;
  height: 50px;
  max-width: 500px;
  width: 350px;
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.search-box i {
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 18px;
  color: #707070;
}

.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  border: none;
  padding: 0 115px 0 56px;
  background-color: transparent;
}

.search-box .search-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 33px;
  padding: 5px 20px;
  background: #e51937;
  border: 1px solid #e51937;
  cursor: pointer;
  font-size: 14px;
}

.manage-dashboard ul li.only-users {
  width: 172px;
}

@media (max-width: 1919px) {
  .left-col ul.left-col-nav li a {
    padding-left: 30px;
    font-size: 18px;
  }

  .left-col ul.left-col-nav li {
    padding: 15px 0;
  }

  .manage-dashboard ul li aside p {
    font-size: 20px;
    line-height: 22px;
  }

  .company-statistic p span {
    font-size: 19px;
    line-height: 22px;
  }

  .left-col ul.left-col-nav li a span {
    background-size: 26px;
  }
}

@media (max-width: 1599px) {
  .left-col ul.left-col-nav li a {
    font-size: 16px;
  }

  .left-col ul.left-col-nav li {
    padding: 10px 0;
  }

  .company-statistic p {
    font-size: 66px;
    line-height: 70px;
  }

  .company-statistic p span {
    font-size: 16px;
    line-height: 20px;
  }

  .table thead tr th {
    height: 60px;
    font-size: 14px;
  }

  .verify-notification {
    margin: 44px 0 70px;
  }

  .signin-btn {
    margin-top: 70px;
  }

  .amc-login-logo {
    width: 155px;
    height: 65px;
  }

  .back-link {
    margin-bottom: 40px;
  }

  .add-step {
    margin-top: 85px;
  }

  .summery-line span {
    font-size: 16px;
  }
}

@media (max-width: 1365px) {

  .left-col {
    width: 19.5%;
  }

  .right-col {
    width: 80.5%;
  }

  .amc-cinema-logo {
    width: 136px;
    height: 59px;
  }

  .back-link {
    margin-bottom: 30px;
  }

  .add-step {
    margin-top: 65px;
  }

  .col-right {
    height: 102px;
  }

  .left-col ul.left-col-nav li {
    padding: 8px 0;
  }

  .verify-notification {
    margin: 38px 0 56px;
  }

  .company-statistic p {
    font-size: 60px;
    line-height: 64px;
  }

  .form-input-grp {
    width: 380px;
  }

  .right-col .company-input-form .form-control {
    height: 56px;
    font-size: 16px;
  }

  .right-col .btnWrap {
    flex-wrap: wrap;
  }

  .manage-dashboard ul li aside {
    height: 222px;
  }

  .form-input-grp .btnWrap .btn-primary {
    height: 56px;
    font-size: 16px;
  }

  .forget-psw-link {
    font-size: 16px;
  }

  .icon-manage-company {
    background-size: 45px;
    height: 52px;
    margin-bottom: 15px;
  }

  .icon-manage-tier {
    background-size: 48px;
    height: 50px;
    margin-bottom: 15px;
  }

  .icon-manage-deals {
    background-size: 60px;
    height: 38px;
    margin-bottom: 15px;
  }

  .icon-manage-users {
    background-size: 46px;
    height: 38px;
    margin-bottom: 15px;
  }

  .icon-view-reports {
    background-size: 32px;
    height: 40px;
    margin-bottom: 15px;
  }

  .left-col ul.left-col-nav li a {
    padding-left: 20px;
  }

  .left-col ul.left-col-nav li a span {
    padding-left: 35px;
  }

  .right-col h1 {
    font-size: 36px;
  }

  .left-col {
    min-height: calc(100vh - 102px - 42px);
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  header {
    padding: 0 0 0 30px;
  }

  .col-right {
    padding-right: 30px;
  }

  .right-col {
    padding: 0 30px 0 60px;
  }

  .manage-dashboard ul {
    gap: unset;
    justify-content: space-between;
  }

  .manage-dashboard ul li {
    width: 156px;
  }

  .manage-dashboard ul li aside p {
    font-size: 17px;
  }

  .company-statistic p span:first-child {
    font-size: 60px;
  }

  .deals-card {
    padding: 60px 88px 60px 41px;
  }

  .deals-card .card .title.cinema-section-title {
    font-size: 21px;
  }

  .selected-section .title {
    line-height: 1;
  }
}

@media (max-width: 1199px) {
  header {
    padding: 0 30px;
  }

  .amc-login-logo {
    width: 145px;
    height: 58px;
  }

  .company-statistic p {
    font-size: 54px;
    line-height: 56px;
  }

  .left-col ul.left-col-nav li a {
    font-size: 14px;
    padding: 4px 0 4px 15px;
  }

  .left-col ul.left-col-nav li a span {
    background-size: 22px;
    padding-left: 25px;
  }

  .form-input-grp {
    width: 350px;
  }

  .right-col .company-input-form .form-control {
    height: 50px;
    font-size: 14px;
  }

  .form-input-grp .btnWrap .btn-primary {
    height: 50px;
    font-size: 14px;
  }

  .forget-psw-link {
    font-size: 14px;
  }

  .manage-dashboard ul li aside p {
    font-size: 16px;
    line-height: 22px;
  }

  .manage-dashboard ul {
    flex-wrap: wrap;
  }

  .manage-dashboard ul li {
    margin-bottom: 20px;
    width: 20%;
  }

  .company-input-form .dropdown-toggle {
    font-size: 14px;
  }
}