@font-face {
  font-family: "gorditaregular";
  src: url("../assets/fonts/gordita-regular-webfont.eot");
  src: url("../assets/fonts/gordita-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/gordita-regular-webfont.woff2") format("woff2"),
    url("../assets/fonts/gordita-regular-webfont.woff") format("woff"), url("../assets/fonts/gordita-regular-webfont.ttf") format("truetype"),
    url("../assets/fonts/gordita-regular-webfont.svg#gorditaregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gorditamedium";
  src: url("../assets/fonts/gordita-medium-webfont.eot");
  src: url("../assets/fonts/gordita-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/gordita-medium-webfont.woff2") format("woff2"),
    url("../assets/fonts/gordita-medium-webfont.woff") format("woff"), url("../assets/fonts/gordita-medium-webfont.ttf") format("truetype"),
    url("../assets/fonts/gordita-medium-webfont.svg#gorditamedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gorditabold";
  src: url("../assets/fonts/gordita-bold-webfont.eot");
  src: url("../assets/fonts/gordita-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/gordita-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/gordita-bold-webfont.woff") format("woff"), url("../assets/fonts/gordita-bold-webfont.ttf") format("truetype"),
    url("../assets/fonts/gordita-bold-webfont.svg#gorditabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gorditalight";
  src: url("../assets/fonts/gordita-light-webfont.eot");
  src: url("../assets/fonts/gordita-light-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/gordita-light-webfont.woff2") format("woff2"),
    url("../assets/fonts/gordita-light-webfont.woff") format("woff"), url("../assets/fonts/gordita-light-webfont.ttf") format("truetype"),
    url("../assets/fonts/gordita-light-webfont.svg#gorditalight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gorditalightitalic";
  src: url("../assets/fonts/gordita-lightitalic-webfont.eot");
  src: url("../assets/fonts/gordita-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/gordita-lightitalic-webfont.woff2") format("woff2"),
    url("../assets/fonts/gordita-lightitalic-webfont.woff") format("woff"), url("../assets/fonts/gordita-lightitalic-webfont.ttf") format("truetype"),
    url("../assets/fonts/gordita-lightitalic-webfont.svg#gorditalight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gorditamediumitalic";
  src: url("../assets/fonts/gordita-mediumitalic-webfont.eot");
  src: url("../assets/fonts/gordita-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/gordita-mediumitalic-webfont.woff2") format("woff2"),
    url("../assets/fonts/gordita-mediumitalic-webfont.woff") format("woff"), url("../assets/fonts/gordita-mediumitalic-webfont.ttf") format("truetype"),
    url("../assets/fonts/gordita-mediumitalic-webfont.svg#gorditalight") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Common Styles */
body {
  font-family: "gorditaregular";
  color: #000;
  background: #fff9f9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: $fontB; */
  color: #000;
  padding: 0;
  margin: 0;
}

p,
ul,
ol,
figure {
  padding: 0;
  margin: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a:focus,
button:focus,
input:focus {
  outline: 0 none;
}

*:focus,
a:hover,
a:focus,
button:hover,
button:focus,
input:focus,
.slick-slide:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
.form-control:focus {
  text-decoration: none;
  outline: 0 none;
  box-shadow: none;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 none !important;
}

ul {
  list-style: none;
}

/* Input type number > remove spin buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Input > remove text clear button */
input::-ms-clear {
  display: none;
}

/* Input type password > remove password reveal button */
input::-ms-reveal {
  display: none;
}

/*.modal-backdrop{background:transparent;} Hided14062020*/

.disabled {
  pointer-events: none !important;
}

.btn:hover {
  transition: all 0.5s;
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
}

.btnWrap .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-family: "gorditabold";
}

.btnWrap .btn.show {
  transition: all 0.5s;
}

.form-input-grp .btnWrap .btn-primary {
  width: 100%;
  height: 66px;
  letter-spacing: 0.5px;
  font-size: 20px;
  line-height: 22px;
}

.btnWrap .btn-primary {
  padding: 5px 20px;
  background: #e51937;
  border: 1px solid #e51937;
  box-shadow: none;
}

.btn-outline-secondary {
  color: #918d8d;
  border-color: #918d8d;
  box-shadow: none;
}

.btnWrap .btn-outline-secondary {
  font-family: "gorditamedium";
  padding: 5px 20px;
  margin-left: 20px;
}

.btnWrap .btn-primary:hover,
.btnWrap .btn-primary:active,
.btnWrap .btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  /* box-shadow: none; */
}


.btnWrap .btn-primary.status-btn:hover,
.btnWrap .btn-primary.status-btn:active,
.btnWrap .btn-primary.status-btn:focus {
  color: #fff;
  background-color: #e51937;
  border-color: #e51937;
  /* box-shadow: none; */
}

.btnWrap .btn-dark:hover,
.btnWrap .btn-dark:active,
.btnWrap .btn-dark:focus {
  color: #fff;
  background-color: #e51937;
  border-color: #e51937;
  /* box-shadow: none; */
}

.btnWrap .btn-outline-secondary:hover,
.btnWrap .btn-outline-secondary:active

/* .btnWrap .btn-outline-secondary:focus { */
  {
  color: #fff;
  /* background-color: #e51937;
  border-color: #e51937; */
  border-color: #918d8d;
  /* box-shadow: none; */
}

/* .btnWrap .btn-primary:active:focus,
.btnWrap .btn-outline-primary:active:focus {
  box-shadow: none;
} */
.btnWrap .btn-secondary {
  color: #000;
  background: #fff;
  border: 1px solid #fff;
}

.btnWrap .btn-black {
  color: #fff;
  background: #000;
  border: 1px solid #000;
}

/* .btnWrap .btn-outline-secondary {
  padding: 5px 20px;
  margin-left: 20px;
} */
.btnWrap .btn-outline-primary {
  background: #fff;
  border: 1px solid #fad1d6;
  color: #918d8d;
}

.btnWrap .btn-small {
  font-size: 14px;
  margin-left: 15px;
  min-width: 120px;
  height: 46px;
}

.btnWrap .btn.dropdown-toggle {
  box-shadow: none;
  background: #fff;
  border: 1px solid #fad1d6;
  color: #918d8d;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 10px;
}

.right-col .btnWrap .btn.dropdown-toggle {
  justify-content: flex-start;
}

.right-col .btn-secondary.show,
.col-right .btn-secondary.show {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-secondary.show::after {
  transform: rotateX(180deg);
}

.btn.dropdown-toggle:active {
  background: #fff;
  border: 1px solid #fad1d6;
  color: #918d8d;
}

.btn.dropdown-toggle {
  min-width: 175px;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-info .dropdown-menu.show {
  box-shadow: rgb(229 25 55 / 10%) 2px 2px 4px 0px;
}

.dropdown-menu.show {
  min-width: 175px;
  border-top: none;
  border-right: 1px solid #fad1d6;
  border-left: 1px solid #fad1d6;
  border-bottom: 1px solid #fad1d6;
  padding: 15px;
  inset: unset !important;
  transform: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
}

/*Sweetalert 2 Styles*/
.swal2-title,
.swal2-html-container {
  font: 22px "gorditaregular";
  color: #918d8d;
}

/* .swal2-actions {
  margin-bottom: 0.9775em;
} */

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  min-width: 95px;
  font-family: "gorditabold";
  border-radius: 22px;
  margin-right: 0;
  margin-left: 0;
  transition: all 0.5s;
}

.swal2-styled.swal2-confirm {
  background: #e51937;
}

.swal2-styled.swal2-cancel {
  font-family: "gorditamedium";
  margin-left: 20px;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background: #000 !important;
}

.rs-picker-menu {
  border-radius: 20px;
}

/* .rs-picker-toggle-wrapper {
  width: 242px !important;
} */

.rs-picker-default .rs-picker-toggle {
  background: #fff;
  border: 1px solid #fad1d6 !important;
  border-radius: 25px;
  box-shadow: none;
  color: #918d8d;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start;
  padding: 5px 43px 5px 20px !important;
  /* width: 242px; */
}

.rs-picker-toggle-caret.rs-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 14px !important;
}

.rs-picker-daterange-predefined.rs-stack {
  gap: 8px !important;
  padding: 7px 0 !important;
}

.rs-stack-item .rs-btn-link {
  color: #e51937 !important;
  margin-left: 8px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #e51937 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #e51937 !important;
}

.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-content:hover {
  background-color: rgb(255 220 225) !important;
}

.rs-calendar-table-cell-content:hover {
  color: #e51937 !important;
}

.rs-btn-primary {
  padding: 5px 20px;
  background: #e51937 !important;
  border: 1px solid #e51937;
  border-radius: 33px;
  font-family: "gorditabold";
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
  background-color: rgb(229 25 55) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.rs-picker-toggle-textbox {
  color: #918d8d;
  font-size: 14px;
}

.rs-picker-toggle-textbox::placeholder {
  font-size: 16px;
}

.rs-picker-daterange-predefined {
  height: 378px;
}

.rs-picker-daterange-header {
  padding: 12px !important;
}

.rs-picker-toggle-textbox {
  padding: 5px 20px !important;
}

.rs-picker-toggle.rs-btn .rs-stack {
  width: 100%;
}

.rs-picker-toolbar.rs-stack {
  align-items: center !important;
}

.rs-picker-toolbar-ranges.rs-stack {
  gap: 20px !important;
}

.rs-picker-toolbar.rs-stack .rs-btn.rs-btn-link {
  margin: 0;
  padding: 0;
}